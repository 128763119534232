/* profile.css */
body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    font: 'Poppins', sans-serif;
}

@media (max-width: 600px) {
    .profile-card {
        /* margin-right: 0px; */
        padding: 0;
        width: 100vw; /* Ensure the card takes full width */
        height: auto; /* Allow the card height to adjust based on content */
        overflow: hidden; /* Prevent overflow */
        border-radius: 0; /* Remove border radius for mobile */
        box-shadow: none; /* Remove shadow for mobile */
    }

    .profile-card-content {
        margin: auto;
      justify-content: center;
        text-align: center;
    }

    .profile-background {
        clip-path: none; /* Remove clip-path on mobile */
    }
}
